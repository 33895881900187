import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Or, "how to not make unnecessary requests with a URLSession subscriber".`}</p>
    <p>{`I've been working on a side project for `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769/status/1066878101907419137"
      }}>{`better CIFilter documentation`}</a>{` lately, and I decided this was as good of a time as any to try out `}<a parentName="p" {...{
        "href": "https://github.com/ReactiveX/RxSwift"
      }}>{`RxSwift`}</a>{`. Even though reactive programming is becoming more and more common in native app development, I've never had a chance to really dig into it, so I started by going through RxSwift's `}<a parentName="p" {...{
        "href": "https://github.com/ReactiveX/RxSwift/blob/master/Documentation/GettingStarted.md"
      }}>{`intro documentation`}</a>{`.`}</p>
    <h2 {...{
      "id": "multiple-subscribers"
    }}>{`Multiple Subscribers`}</h2>
    <p>{`One thing that wasn't immediately intuitive to me about Observables is that any time `}<inlineCode parentName="p">{`subscribe`}</inlineCode>{` is called, the observable's creation block gets called again, even for Observables which perform asynchronous work. Take a simple observable that generates integers as an example:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`generateRandomIntAfterDelay`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() -> Observable<`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`> {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Observable.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`create`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { observer `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Processing request..."`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        DispatchQueue.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`main`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`asyncAfter`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`deadline`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: DispatchTime.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`now`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() + `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Generating random int..."`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            observer.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`onNext`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`random`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`in`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` ..< `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`10`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`))`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            observer.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`onCompleted`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Disposables.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`create`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`If you have multiple subscribers to this observable, that means multiple units of work will get enqueued, and multiple random ints will get returned. I.e., if our subscription code looks like this:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` observable = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`generateRandomIntAfterDelay`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`observable.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`subscribe`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`onNext`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: { randInt `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"1: Got `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`\\\\`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`(randInt)"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`})`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`observable.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`subscribe`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`onNext`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: { randInt `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"2: Got `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`\\\\`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`(randInt)"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`})`}</span></span></code></pre>
    <p>{`Then the output will look like this:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "nohighlight"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Processing request...`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Processing request...`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Generating random int...`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`1: Got 7`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Generating random int...`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`2: Got 2`}</span></code></pre>
    <p>{`For some reason, I assumed that for async observables, two subscriptions would result in two outputs, but for the same async result - this is just not how observable subscriptions work.`}</p>
    <h2 {...{
      "id": "observable-urlsession-tasks"
    }}>{`Observable URLSession Tasks`}</h2>
    <p>{`This situation gets a little trickier when you have an observable which performs real work, like making http requests:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`extension`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Reactive`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`where`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Base: URLSession {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`public`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`response`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`urlString`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) -> Observable<Data> {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Observable.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`create`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { observer `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` task = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`base`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`dataTask`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`with`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`URLRequest`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`url`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`URL`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: urlString)!)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            ) { (data, response, error) `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`guard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` data = data `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                observer.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`onNext`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(data)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                observer.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`onCompleted`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            task.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`resume`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Disposables.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`create`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                task.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`cancel`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`makeRequest`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` observable = URLSession.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`shared`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`rx`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`response`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`urlString`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"https://noahgilmore.com"`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    observable.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`subscribe`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`onNext`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: { data `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Got the data!"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    })`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    observable.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`subscribe`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`onNext`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: { data `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Got the data again!"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    })`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`One call to `}<inlineCode parentName="p">{`makeRequest()`}</inlineCode>{` will make `}<em parentName="p">{`two`}</em>{` HTTP requests and get back two separate data objects. I started to wonder about how you would correctly implement multiple subscribers for a single HTTP request - it turns out an easy way is to use `}<inlineCode parentName="p">{`ConnectableObservable`}</inlineCode>{`, an "observable sequence that shares a single subscription to the underlying sequence"`}<a parentName="p" {...{
        "href": "https://github.com/ReactiveX/RxSwift/blob/53cd723d40d05177e790c8c34c36cec7092a6106/RxSwift/Observables/Multicast.swift#L54"
      }}>{`[1]`}</a>{`. Calling `}<inlineCode parentName="p">{`publish()`}</inlineCode>{` on any observable returns a `}<inlineCode parentName="p">{`ConnectableObservable`}</inlineCode>{` which mirrors it, and you can start the sequence with `}<inlineCode parentName="p">{`.connect()`}</inlineCode>{`. This means you can implement multiple subscriptions for a single unit of work like this:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`makeRequest`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` observable = URLSession.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`shared`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`rx`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`response`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`urlString`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"https://noahgilmore.com"`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` connectableObservable = observable.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`publish`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    connectableObservable.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`subscribe`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`onNext`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: { data `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Got the data!"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    })`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    connectableObservable.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`subscribe`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`onNext`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: { data `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Got the data again!"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    })`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// Start the request`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    connectableObservable.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`connect`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`This code correctly only makes one HTTP request with two callbacks. 👌`}</p>
    <p>{`This isn't the only time `}<inlineCode parentName="p">{`.publish()`}</inlineCode>{` is useful though - like `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/40052529/in-rxjs-5-is-there-a-way-to-trigger-an-observable-before-subscribing-to-it?rq=1"
      }}>{`asked in this Stackoverflow`}</a>{`, you sometimes want to kick off a long-running piece of work before you actually subscribe to an observable. For example:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`longRunningTask`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() -> Observable<Result> {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Observable.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`create`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { observer `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        doSomeLongRunningWork { result `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            observer.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`onNext`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(result)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            observer.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`onCompleted`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`startTask`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` connectable = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`longRunningTask`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`().`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`publish`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    connectable.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`connect`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// start the task`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// Do more setup...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    connectable.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`subscribe`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`onNext`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: { result `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// process the result`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    })`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Hopefully this helps explain an unintuitive concept for others who are starting out with reactive programming. If you know a better way or a best practice to do this sort of thing, `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769"
      }}>{`let me know`}</a>{`! For reference, I've implemented these examples on `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/RxSwift-practice/blob/ca092d91791e40a98980607c0d72caa774d1a509/rxswift-practice/Tests.swift#L85"
      }}>{`Github`}</a>{`.`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      